/*
Template Name: Color Admin - Responsive Admin Dashboard Template build with Twitter Bootstrap 4
Version: 4.2.0
Author: Sean Ngu
Website: http://www.seantheme.com/color-admin-v4.2/admin/
*/

.header .navbar-logo,
.login .login-header .brand .logo,
.header .navbar-nav > li > a .label,
.label.label-theme,
.btn.btn-scroll-to-top {
	background: #00acac;
}
.header.navbar-inverse .navbar-brand {
	color: #fff !important;
}
.text-theme,
.sidebar .nav > li.active > a i {
	color: #00acac;
}
.sidebar .sub-menu > li.active:after {
	border-color: #00acac;
}


/* Added in v1.5 */

.gradient-enabled .sidebar .nav > li.active > a, 
.gradient-enabled .sidebar .nav > li.active > a:focus, 
.gradient-enabled .sidebar .nav > li.active > a:hover,
.gradient-enabled.page-sidebar-minified .sidebar .nav > li.has-sub.active:focus > a, 
.gradient-enabled.page-sidebar-minified .sidebar .nav > li.has-sub.active:hover > a, 
.gradient-enabled.page-sidebar-minified .sidebar .nav > li.has-sub.active > a,
.gradient-enabled .label.label-theme,
.gradient-enabled .btn.btn-scroll-to-top,
.gradient-enabled .top-menu .nav > li.active > a, 
.gradient-enabled .top-menu .nav > li.active > a:focus, 
.gradient-enabled .top-menu .nav > li.active > a:hover {
	background: rgb(0,210,210); /* Old browsers */
	/* IE9 SVG, needs conditional override of 'filter' to 'none' */
	background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwZDJkMiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMGFjYWMiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
	background: -moz-linear-gradient(top,  rgba(0,210,210,1) 0%, rgba(0,172,172,1) 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0,210,210,1)), color-stop(100%,rgba(0,172,172,1))); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top,  rgba(0,210,210,1) 0%,rgba(0,172,172,1) 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top,  rgba(0,210,210,1) 0%,rgba(0,172,172,1) 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top,  rgba(0,210,210,1) 0%,rgba(0,172,172,1) 100%); /* IE10+ */
	background: linear-gradient(to bottom,  rgba(0,210,210,1) 0%,rgba(0,172,172,1) 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00d2d2', endColorstr='#00acac',GradientType=0 ); /* IE6-8 */
}
.gradient-enabled .sidebar .nav > li.active > a {
	z-index: 10;
}
.gradient-enabled .sidebar .nav > li.active > a i,
.gradient-enabled .sidebar .nav > li.active > a .caret {
	color: rgba(0,0,0,0.5);
}
.pace-progress {
	background: #00acac;
}
.pace .pace-activity {
	border-top-color: #00acac;
	border-left-color: #00acac;
}


/* Added in v1.7 */

.page-with-light-sidebar .sidebar .nav > li.active > a > i {
	color: #00acac;
}
.page-with-light-sidebar .sidebar .nav > li.active > a .badge,
.page-with-light-sidebar .sidebar .nav > li.active > a .label.label-theme {
	background: #00acac;
}


/* Added in v1.9 */

.top-menu .nav > li.active > a i, 
.top-menu .nav > li.active > a:focus i, 
.top-menu .nav > li.active > a:hover i {
	color: #00acac;
}
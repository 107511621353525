/* You can add global styles to this file, and also import other style files */

// Any files that are in ../common/styles can be included anywhere
// In the project without needing a relative path
@import "variables";


/// ------------------------------------------------------------------------ ///
/// FONTS 
/// ------------------------------------------------------------------------ ///


// Import a font
// 1. Add your font files to ../common/assets/fonts/
// 2. Create a .scss for your font in ../common/styles/fonts/
// 3. Import that font here

// Nunito
// $nunito-font-path: '../common/assets/fonts/Nunito';     
// @import "fonts/nunito.scss";

// Font Awesome (for icons)
$fa-font-path : '../../node_modules/font-awesome/fonts';
@import '../../node_modules/font-awesome/scss/font-awesome';

/// ------------------------------------------------------------------------ ///
/// CUSTOMIZE BOOTSTRAP 
/// ------------------------------------------------------------------------ ///

// Apply a font to the bootstrap system
// $font-family-base: 'Open Sans';
// $navbar-brand-font-size: 0.9rem;
// $line-height-base: 0.8;

/// ------------------------------------------------------------------------ ///
/// IMPORT BOOTSTRAP 
/// ------------------------------------------------------------------------ ///

@import '../../node_modules/bootstrap/scss/bootstrap';

/// ------------------------------------------------------------------------ ///
/// CUSTOM
/// ------------------------------------------------------------------------ ///

@import '../../node_modules/angular-notifier/styles';

@import './theme/default/style.css';
@import './theme/default/style-responsive.css';
@import './theme/default/theme/default.css';
@import './theme/e-commerce/style.css';
@import './theme/default/theme/red.css';

.clickable {
  cursor: pointer;
}

.shadow-none {
  outline: none;
}

.breadcrumb {
  li {
    a {
      color: #00acac 
    }
  }
}

.breadcrumbactive {
  color: #333 !important;
}

/* This is for the correct highlighting of form field states */
.ng-touched.ng-invalid {
  @extend .is-invalid;
}

.ng-touched.ng-valid {
  @extend .is-valid;
}



th[sortable] {
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
}

th[sortable].desc:before, th[sortable].asc:before {
  content: '';
  display: block;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAAXNSR0IArs4c6QAAAmxJREFUeAHtmksrRVEUx72fH8CIGQNJkpGUUmakDEiZSJRIZsRQmCkTJRmZmJgQE0kpX0D5DJKJgff7v+ru2u3O3vvc67TOvsdatdrnnP1Y///v7HvvubdbUiIhBISAEBACQkAICAEhIAQ4CXSh2DnyDfmCPEG2Iv9F9MPlM/LHyAecdyMzHYNwR3fdNK/OH9HXl1UCozD24TCvILxizEDWIEzA0FcM8woCgRrJCoS5PIwrANQSMAJX1LEI9bqpQo4JYNFFKRSvIgsxHDVnqZgIkPnNBM0rIGtYk9YOOsqgbgepRCfdbmFtqhFkVEDVPjJp0+Z6e6hRHhqBKgg6ZDCvYBygVmUoEGoh5JTRvIJwhJo1aUOoh4CLPMyvxxi7EWOMgnCGsXXI1GIXlZUYX7ucU+kbR8NW8lh3O7cue0Pk32MKndfUxQFAwxdirk3fHappAnc0oqDPzDfGTBrCfHP04dM4oTV8cxr0SVzH9FF07xD3ib6xCDE+M+aUcVygtWzzbtGX2rPBrEUYfecfQkaFzYi6HjVnGBdtL7epqAlc1+jRdAap74RrnPc4BCijttY2tRcdN0g17w7HqZrXhdJTYAuS3hd8z+vKgK3V1zWPae0mZDMykadBn1hTQBLnZNwVrJpSe/NwEeDsEwCctEOsJTsgxLvCqUl2ACftEGvJDgjxrnBqkh3ASTvEWrIDQrwrnJpkB3DSDrGW7IAQ7wqnJtkBnLRztejXXVu4+mxz/nQ9jR1w5VB86ejLTFcnnDwhzV+F6T+CHZlx6THSjn76eyyBIOPHyDakhBAQAkJACAgBISAEhIAQYCLwC8JxpAmsEGt6AAAAAElFTkSuQmCC') no-repeat;
  background-size: 22px;
  width: 22px;
  height: 22px;
  float: left;
  margin-left: -22px;
}

th[sortable].desc:before {
  transform: rotate(180deg);
  -ms-transform: rotate(180deg);
}